import { FC } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Edit,
  EditProps,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TextField,
  TextInput,
} from 'react-admin';

import SimpleForm from 'components/SimpleForm';

export const SoftwareReleaseEdit: FC<EditProps> = () => {
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm>
        <TextInput source="name" placeholder="Pixie v17.1.0" fullWidth />
        <ReferenceInput source="hardware_id" reference="hardware">
          <AutocompleteInput optionText="name" fullWidth />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.hardware_id && (
              <ReferenceInput
                source="hardware_revision_id"
                reference="hardware_revisions"
                filter={{ hardware_id: formData.hardware_id }}
              >
                <SelectInput optionText="name" fullWidth />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <BooleanInput source="custom" />

        <ArrayInput source="software_components" resource="software_components">
          <SimpleFormIterator disableAdd disableClear disableReordering disableRemove fullWidth>
            <FormDataConsumer>
              {({ formData, scopedFormData, getSource, ...rest }) =>
                scopedFormData?.name && <TextField source={getSource!('name')} {...rest} />
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, scopedFormData, getSource }) =>
                scopedFormData?.id && (
                  <ReferenceInput
                    reference="software_versions"
                    source={getSource!('software_version_id')}
                    filter={{
                      hardware_id: formData.hardware_id,
                      software_component_id: scopedFormData.id,
                      type: !formData.custom ? 'tag' : undefined,
                    }}
                  >
                    <AutocompleteInput label="resources.software_versions.fields.version" optionText="name" fullWidth />
                  </ReferenceInput>
                )
              }
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
